export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0];

export const dictionary = {
		"/(main)/(marketing)/(landing-pages)": [18,[3,6,8],[4]],
		"/(no-header-footer)/activation": [34,[10]],
		"/(main)/(blog)/blog": [12,[5]],
		"/(main)/(blog)/blog/articles/[slug]": [13,[5]],
		"/(main)/(blog)/blog/categories": [14,[5]],
		"/(main)/(blog)/blog/categories/[category]": [15,[5]],
		"/(no-header-footer)/changer-email": [35,[10]],
		"/(no-header-footer)/changer-mot-de-passe": [36,[10]],
		"/(main)/compte/[...slug]": [24,[3],[4]],
		"/(no-header-footer)/confirmer-email": [37,[10]],
		"/(main)/contact": [~25,[3],[4]],
		"/(main)/creer-un-compte": [26,[3],[4]],
		"/(main)/(marketing)/(hero)/entreprises/levez-des-fonds/[slug]": [17,[3,6,7],[4]],
		"/(main)/evenements": [27,[3],[4]],
		"/(main)/fil-actualite": [28,[3],[4]],
		"/(main)/ghost": [29,[3],[4]],
		"/(main)/(marketing)/(landing-pages)/home-page": [19,[3,6,8],[4]],
		"/(kyc)/inscription": [11,[2]],
		"/(main)/(marketing)/(landing-pages)/investir-en-crowdfunding-immobilier": [20,[3,6,8],[4]],
		"/(main)/(marketing)/(landing-pages)/investir-fonds-investissement": [21,[3,6,8],[4]],
		"/(main)/(marketing)/(landing-pages)/investir-startup": [22,[3,6,8],[4]],
		"/(no-header-footer)/investir/[...slug]": [38,[10]],
		"/(main)/(marketing)/(no-hero)/juridique/[slug]": [23,[3,6,9],[4]],
		"/(no-header-footer)/oauth": [39,[10]],
		"/(main)/portefeuille/[...slug]": [30,[3],[4]],
		"/(main)/projets": [32,[3],[4]],
		"/(main)/projet/[...slug]": [31,[3],[4]],
		"/(main)/statistiques": [33,[3],[4]],
		"/(main)/(marketing)/(hero)/[...slug]": [16,[3,6,7],[4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';